<template>
  <div>
    <div class="main">
      <v-skeleton-loader
        type="table"
        v-if="loading"
        class="mb-3"
      ></v-skeleton-loader>
      <v-row v-else>
        <v-col cols="12">
          <v-sheet :rounded="'lg'">
            <v-card-title>
              <v-row>
                <v-col> Problèmes liés a l'envoi des SMS Cartes </v-col>
                <v-col>
                  <!-- <v-text-field
                    v-model="filter.keyword"
                    rounded
                    filled
                    dense
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                    placeholder="Rechercher..."
                  /> -->
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <div v-if="loading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>

              <div v-else>
                <div v-if="transactions.data.length > 0">
                  <v-data-table
                    :headers="headers"
                    :items="transactions.data"
                    :single-expand="true"
                    :expanded.sync="expanded"
                    :hide-default-header="true"
                    :hide-default-footer="true"
                    item-key="id"
                    show-expand
                    :items-per-page="filter.size"
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:header="{}">
                      <thead style="widows: 100%">
                        <tr style="widows: 100%">
                          <th class="text-no-wrap">ID</th>
                          <th class="text-no-wrap">Nom</th>
                          <th class="text-no-wrap">Date</th>
                          <th class="text-no-wrap">Montant</th>
                          <th class="text-no-wrap">Frais Service</th>
                          <th class="text-no-wrap">Type</th>
                          <th class="text-no-wrap">Service</th>
                          <th class="text-no-wrap">Action</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr style="widows: 100%">
                        <td class="text-no-wrap">{{ item.id }}</td>

                        <td
                          class="text-no-wrap font-weight-medium"
                          @click="showAccount(item.account_id)"
                          style="cursor: pointer"
                        >
                          <v-avatar size="35">
                            <v-img :src="require('@/assets/avatar.png')" />
                          </v-avatar>
                          {{
                            item.account
                              ? item.account.user
                                ? item.account.user.name
                                : "pas de user"
                              : "pas de compte"
                          }}
                        </td>

                        <td class="text-no-wrap">
                          {{ item.created_at }}
                        </td>

                        <td class="text-no-wrap font-weight-medium gifty--text">
                          {{ CurrencyFormatting(item.amount) }} DZD
                        </td>

                        <td class="text-no-wrap font-weight-bold gifty--text text-center">
                            <span v-if="item.transaction_fee">
                                {{ CurrencyFormatting(item.transaction_fee.fee_amount) }} DZD
                            </span>
                            <span v-else>
                                /
                            </span>
                        </td>

                        <td class="text-no-wrap">
                          <v-btn
                            text
                            color="grey"
                            v-if="item.type === 'Idoom' || item.type === '4G'"
                            class="text-none"
                          >
                            <v-icon left color="info">
                              mdi-card-multiple
                            </v-icon>
                            Idoom
                          </v-btn>
                        </td>

                        <td class="text-no-wrap">
                          Service
                          {{ item.service != null ? item.service.name : "-" }}
                        </td>

                        <td class="text-no-wrap d-flex align-center">
                          <div v-if="can_cancel(item)">
                            <v-btn
                              small
                              icon
                              @click="cancelTransaction(item)"
                              class="red"
                            >
                              <v-icon x-small class="white--text"
                                >mdi-close-thick</v-icon
                              >
                            </v-btn>
                          </div>

                          <v-btn @click.prevent="[expand(!isExpanded)]" icon>
                            <v-icon v-if="!isExpanded"
                              >mdi-plus-circle-outline</v-icon
                            >
                            <v-icon v-if="isExpanded"
                              >mdi-minus-circle-outline</v-icon
                            >
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <DetailsCard
                          :item="item"
                          class="pa-4 grey lighten-5"
                          v-if="item.type === 'Idoom' || item.type === '4G'"
                          @resendSMS="[resendSms($event)]"
                          @openMartchipCard="openMartchipCard($event)"
                        />
                      </td>
                    </template>
                  </v-data-table>

                  <v-divider />

                  <div
                    class="justify-space-between pl-4 pt-6 pr-4 d-md-flex pb-4"
                  >
                    <div>
                      <v-select
                        outlined
                        v-model="filter.size"
                        dense
                        :items="[5, 10, 20, 30, 40, 50]"
                        @change="onPageChange"
                      ></v-select>
                    </div>

                    <v-spacer />

                    <v-pagination
                      class="px-3 mb-4"
                      total-visible="6"
                      v-if="transactions.total > filter.size"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(transactions.total / filter.size)"
                      @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>

                <div v-else class="text-center pb-5">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <MartchipCard ref="martchipCard" />
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";

import DetailsCard from "@/views/support/components/DetailsCard.vue";
import MartchipCard from "@/views/cards/components/MartchipCard.vue";

export default {
  components: { DetailsCard, MartchipCard },
  data() {
    return {
      loading: true,
      transactions: [],

      filter: {
        keyword: "",
        size: 10,
        page: 1,
      },

      // Headers
      expanded: [],
      singleExpand: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nom", value: "account.user.name" },
        { text: "Date", value: "created_at" },
        { text: "Montant", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Service", value: "service.name" },
        { text: "Actions", value: "" },
      ],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("/anomaly/cards/sms", {params: {
        ...this.filter
      }})
        .then((res) => {
          this.transactions = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    onPageChange() {
      this.fetchData();
    },
    can_cancel() {
      return false;
    },
    openMartchipCard(item) {
      this.$refs.martchipCard.open(item);
    },
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {},
  },
  mounted() {
    this.fetchData();
  },
};
</script>
  
  <style scoped></style>