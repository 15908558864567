<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="3">
          <v-card class="rounded-lg" elevation="0">
            <v-card-text>
              <v-list nav>
                <v-list-item-group v-model="model" mandatory>
                  <v-list-item
                    color="primary"
                    class="translateY nav-list"
                    active-class="border--primary"
                    v-for="(item, i) in items"
                    :key="i"
                    :value="item.value"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary" dense>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <MartchipCallback v-show="model === 1" ref="martchipCallback" />
          <SmsCards v-show="model === 2" ref="smsCards" />
          <consumedCards v-show="model === 3" ref="consumedCards" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  
  
  <script>
import MartchipCallback from "./cards/martship.vue";
import SmsCards from "./cards/sms.vue";
import consumedCards from "./cards/consumed.vue";

export default {
  components: { MartchipCallback, SmsCards, consumedCards },
  data() {
    return {
      items: [
        {
          text: "MartchipCallback",
          icon: "mdi-clipboard-text-outline",
          value: 1,
        },
        { text: "SMS non Envoyé", icon: "mdi-clipboard-text-outline", value: 2 },
        { text: "Consumed Card duplication", icon: "mdi-clipboard-text-outline", value: 3 },
      ],
      model: 1,
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.$refs.martchipCallback.fetchData();
  },
  watch: {
    model(val) {
      switch (val) {
        case 1:
          return this.$refs.martchipCallback.fetchData();
        case 2:
          return this.$refs.smsCards.fetchData();
        case 3:
          return this.$refs.consumedCards.fetchData();
      }
    },
  },
};
</script>
