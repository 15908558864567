<template>
  <div>
    <div class="main">
      <v-skeleton-loader
        type="table"
        v-if="loading"
        class="mb-3"
      ></v-skeleton-loader>
      <v-row v-else>
        <v-col cols="12">
          <v-chip
            color="gifty"
            :input-value="tab == 0"
            filter
            dark
            label
            @click="setTab(0)"
          >
            Cartes consommées sans Transactions
            <v-icon small right>mdi-ticket-percent</v-icon>
          </v-chip>

          <v-chip
            class="ml-4"
            color="blue"
            :input-value="tab == 1"
            filter
            dark
            label
            @click="setTab(1)"
          >
            Cartes consommées mais non utilisées
            <v-icon small right>mdi-ticket-percent</v-icon>
          </v-chip>
        </v-col>

        <v-col cols="12">
          <v-sheet :rounded="'lg'">
            <v-card-title>
              <v-row>
                <v-col> {{ titles[tab] }}</v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <div v-if="loading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>

              <div v-else>
                <div v-if="cards.data.length > 0">
                  <v-data-table
                    :headers="headers"
                    :items="cards.data"
                    :single-expand="true"
                    :expanded.sync="expanded"
                    :hide-default-header="true"
                    :hide-default-footer="true"
                    item-key="id"
                    show-expand
                    :items-per-page="filter.size"
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:header="{}">
                      <thead style="widows: 100%">
                        <tr style="widows: 100%">
                          <th class="text-no-wrap">ID</th>
                          <th class="text-no-wrap">Date</th>
                          <th class="text-no-wrap">Montant</th>
                          <th class="text-no-wrap">Frais Service</th>
                          <th class="text-no-wrap">Compte</th>
                          <th class="text-no-wrap">Choix</th>
                          <th class="text-no-wrap">Nbr SMS envoyés</th>
                          <th class="text-no-wrap">Carte Utilisée ?</th>
                          <th class="text-no-wrap">ID Carte</th>
                          <th class="text-no-wrap">Code Carte</th>
                          <th class="text-no-wrap">Transaction</th>
                          <th class="text-no-wrap">Action</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item}">
                      <tr style="widows: 100%">
                        <td class="text-no-wrap">{{ item.id }}</td>

                        <td class="text-no-wrap font-weight-bold">
                          {{ item.created_at }}
                        </td>

                        <td class="text-no-wrap font-weight-bold gifty--text">
                          {{ CurrencyFormatting(item.card.amount) }} DZD
                        </td>

                        <td class="text-no-wrap font-weight-bold gifty--text text-center">
                            <span v-if="item.transaction && item.transaction.transaction_fee">
                                {{ CurrencyFormatting(item.transaction.transaction_fee.fee_amount) }} DZD
                            </span>
                            <span v-else>
                                /
                            </span>
                          
                        </td>

                        <td class="text-no-wrap font-weight-bold">
                          {{ item.account.user.name }}
                        </td>

                        <td class="font-weight-bold text-center">
                          {{ item.choice }}
                        </td>
                        <td class="font-weight-bold text-center">
                          {{ item.sms_sent }}
                        </td>
                        <td class="font-weight-bold text-center">
                          {{ item.card.used == 1 ? "Oui" : "Non" }}
                        </td>

                        <td class="font-weight-bold text-center blue--text">
                          {{ item.card ? item.card.id : "-" }}
                        </td>

                        <td class="font-weight-bold text-center blue--text">
                          {{ item.card ? item.card.card_number : "-" }}
                        </td>

                        <td class="font-weight-bold text-center">
                          {{ item.transaction ? item.transaction.id : "-" }}
                        </td>

                        <td class="font-weight-bold text-center">
                          <v-btn
                            icon
                            class="error--text"
                            @click="deleteConsumedCard(item)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <DetailsCard
                          :item="item"
                          class="pa-4 grey lighten-5"
                          v-if="item.type === 'Idoom' || item.type === '4G'"
                          @resendSMS="[resendSms($event)]"
                          @openMartchipCard="openMartchipCard($event)"
                        />
                      </td>
                    </template>
                  </v-data-table>

                  <v-divider />

                  <div
                    class="justify-space-between pl-4 pt-6 pr-4 d-md-flex pb-4"
                  >
                    <div>
                      <v-select
                        outlined
                        v-model="filter.size"
                        dense
                        :items="[5, 10, 20, 30, 40, 50]"
                        @change="onPageChange"
                      ></v-select>
                    </div>

                    <v-spacer />

                    <v-pagination
                      class="px-3 mb-4"
                      total-visible="6"
                      v-if="cards.total > filter.size"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(cards.total / filter.size)"
                      @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>

                <div v-else class="text-center pb-5">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <MartchipCard ref="martchipCard" />
    <DeleteConsumedCard ref="deleteConsumedCard" @refresh="fetchData()" />
  </div>
</template>
    
<script>
import { HTTP } from "@/http-common";

import DetailsCard from "@/views/support/components/DetailsCard.vue";
import MartchipCard from "@/views/cards/components/MartchipCard.vue";
import DeleteConsumedCard from "../DeleteConsumedCard.vue";

export default {
  components: { DetailsCard, MartchipCard, DeleteConsumedCard },
  data() {
    return {
      tab: 0,
      loading: true,
      cards: [],

      filter: {
        keyword: "",
        size: 10,
        page: 1,
      },

      titles: [
        "Cartes consommées sans Transactions",
        "Cartes consommées mais non utilisées (status)",
      ],

      urls: [
        "/anomaly/cards/consumedCardsAnomaly",
        "/anomaly/cards/consumedCardsNotUsedAnomaly",
      ],
      // Headers
      expanded: [],
      singleExpand: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nom", value: "account.user.name" },
        { text: "Date", value: "created_at" },
        { text: "Montant", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Service", value: "service.name" },
        { text: "Actions", value: "" },
      ],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get(this.urls[this.tab], {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.cards = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    onPageChange() {
      this.fetchData();
    },
    can_cancel() {
      return false;
    },
    openMartchipCard(item) {
      this.$refs.martchipCard.open(item);
    },

    deleteConsumedCard(item) {
      this.$refs.deleteConsumedCard.open(item);
    },
    setTab(id) {
      this.tab = id;
      this.filter.page = 1;
      this.fetchData();
    },
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {},
  },
  mounted() {
    this.fetchData();
  },
};
</script>
    
    <style scoped></style>